import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

function WhoSponsor() {
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          // backgroundImage: `url(${svg})`,
          // backgroundPosition: "top right",
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "100%",
        }}
        className="section py-0"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12} className="my-5">
              <h5 className="text-400 text-white mt-0">
                Sponsorship at Finnovex West Africa offers a unique opportunity to distinguish your
                brand and connect with senior buyers from the financial services industry. Leverage
                our comprehensive delegate research, cutting-edge insights, and extensive network to
                elevate your brand in the African region.
              </h5>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={12}>
              <h2
                className="text-white mt-3 text-700 text-center mb-5"
                style={{ color: "#003972" }}
              >
                Top Reasons To Sponsor
              </h2>
            </Col>
            {content.map((s, i) => (
              <Col lg={6} className="mb-5">
                <ScrollAnimation animateIn={"fadeInDown"} animateOnce={true} duration={1.5}>
                  <img src={require(`assets/quotes/${i + 1}.jpg`)} width="100%" alt="main logo" />
                  <hr />
                  <h3 className="text-white text-700">{s.title}</h3>
                  <h5 className="text-white text-400 mt-2">{s.description}</h5>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
          <Row className="justify-content-center">
            <Col md={12} xs={12} className="mb-1 mt-3">
              <Container fluid>
                <Row>
                  <Col lg={4} xs={12} className={`px-1`}>
                    <Button className="px-3 py-2 my-5 rounded-0" color="primary" href="/register">
                      <p className="m-0 text-700" style={{ color: "#fff" }}>
                        Get a Sponsorship Kit
                      </p>
                    </Button>{" "}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section py-0"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12} className="mt-5 text-center p-3 mb-4">
              <h5 className="text-400 mt-0 text-white p-3">
                Sponsorship offers a unique opportunity to set your brand apart and connect with
                senior buyers from the financial services industry. Benefit from our comprehensive
                delegate research, cutting-edge insights, and extensive network to elevate your
                brand in the West Africa.
                <br />
                <br />
                Contact us today at{" "}
                <a href="mailto:info@exibex.com" className="text-primary">
                  <b>info@exibex.com</b>
                </a>{" "}
                or call us on{" "}
                <a href="tel:+971 585518437" className="text-primary">
                  <b>+971 585518437</b> /
                </a>{" "}
                <a href="tel:+91 9980028128" className="text-primary">
                  <b>+91 9980028128</b>
                </a>{" "}
                to learn more. Our team is ready to help you seize this opportunity and make a
                powerful impact in your industry. Let's take your brand to the top together!
              </h5>

              <br />
              <Container>
                <Row className="justify-content-center">
                  <Col lg={4}>
                    <Button
                      href="mailto:info@exibex.com"
                      className="btn my-2 text-center px-5"
                      color="primary"
                      size="lg"
                    >
                      Contact Us
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default WhoSponsor;

const content = [
  {
    title: "Network with Top Decision Makers",
    description: `Connect with over 150+ influential delegates in West Africa's financial industry. Build relationships and influence individuals with significant authority.`,
    image: "decision.png",
  },
  {
    title: "Target Your Audience Precisely",
    description: `Benefit from comprehensive delegate research to engage with relevant stakeholders in West Africa. Gain insights and network with industry leaders.`,
    image: "competition.png",
  },
  {
    title: "Explore Cutting-Edge Insights",
    description: `Access the latest financial marketing concepts, actionable insights, and networking opportunities with industry professionals in West Africa. Stay ahead of industry practices and build valuable connections.`,
    image: "one.png",
  },
  {
    title: "Access Industry Knowledge",
    description: `Gain insights into the rapidly-evolving West African banking landscape with top-tier insights and solutions in Fintech digital strategies.`,
    image: "showcase.png",
  },
  // {
  //   title: "Stay ahead of the competition in the rapidly-evolving banking landscape. ",
  //   description: `Join us to learn about the latest Fintech digital strategies that will transform your organisation's strategy and marketing for sustained growth. Gain a competitive edge and stand tall among your peers in the industry.`,
  //   image: "competition.png",
  // },
  {
    title: "Leverage Our Experience",
    description: `Access our extensive network of 150,000+ contacts from various editions. Benefit from our brand strategy to gain a competitive edge in the West African market.`,
    image: "competition.png",
  },
];

import React from "react";
import Slider from "react-slick";
import { Container, Row, Col } from "reactstrap";
import Card from "reactstrap/lib/Card";

function Event() {
  let pageHeader = React.createRef();
  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    rows: 2,
    className: "p-10",
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          row: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          row: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section py-3"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h2 className="text-700 text-white mb-5 text-center">OUR SERIES IS GEARING UP</h2>
            </Col>
            <Col lg={12} xs={12} className="mt-2">
              <Slider {...settings}>
                {content.map((c) => (
                  <div style={{ backgroundColor: "transparent" }} className="p-1">
                    <a target="_blank" href={c.link} rel="noopener noreferrer">
                      <img
                        src={require(`assets/images/logo/${c.image}`)}
                        alt="ea"
                        width="100%"
                        // style={{ maxWidth: "270px", padding: "5px" }}
                      />
                    </a>
                  </div>
                ))}
              </Slider>
            </Col>
            <Col lg={12}>
              <h3 className="text-700 my-5 text-center text-white">
                KNOW MORE AT -{" "}
                <a href="http://finnovex.com" className="text-primary text-700">
                  WWW.FINNOVEX.COM
                </a>
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "white",
        }}
        className="section py-3"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} className="p-4 text-center">
              <a href="http://exibex.com" className="">
                <img
                  src={require("assets/images/logo/exibex.gif")}
                  className="mx-auto mb-3"
                  alt="exibex"
                  width="100%"
                  style={{ maxWidth: "270px" }}
                />
              </a>
              <h5 className="text-400 text-dark text-justify mt-0">
                Exibex is a market leader in producing knowledge-based conferences, managing events,
                and providing in-depth business consulting. We create engaging networking platforms
                for C-level communities of global corporations, helping organisations navigate
                volatile markets and achieve their business goals.
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Event;
const content = [
  { image: "Finnovex-West-Africa-2024.png", link: "https://wa.finnovex.com" },
  { image: "Finnovex-North-Africa-2024.png", link: "https://finnovex.com" },
  { image: "Finnovex-East-Africa-2024.png", link: "https://ea.finnovex.com" },
  { image: "Finnovex-Saudi-Arabia-2024.png", link: "https://ksa.finnovex.com" },
  { image: "Finnovex-Qatar-2024.png", link: "https://qa.finnovex.com" },
  { image: "Finnovex-South-Africa-2024.png", link: "https://sa.finnovex.com" },
  { image: "Finnovex-Middle-East-2024.png", link: "https://me.finnovex.com" },
  // { image: "Finnovex-Europe-2024.png", link: "https://eu.finnovex.com" },
];
